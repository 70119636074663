:root {
  --color-green1: #1f622f;
  --color-green2: #2e9f46;
  --color-green3: #4fbb4a;
  --color-blue1: #152b5f;
  --color-blue2: #186d9c;
  --color-yellow1: #bdd750;

  /* --color-bg1: #F5F5F5; */
  --color-bg1: #dee2e6;
  --color-bg2: #f4f5f7;
  --color-bg3: #bdd750;
  --color-red: #ec1d24;
  --color-text-1: #222222;
}

.card {
  margin: 20px 0;
  width: 100%;
  padding: 2rem;
  /* aspect-ratio: 2/1; */
  /* max-height: 510px; */
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 70% 30%;
  border-radius: 10px;
  transition: transform 250ms;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card img {
  max-width: 100%;
  object-fit: contain;
}

.card:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.content {
  max-width: 100%;
}

.content img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

.postdetails a {
  pointer-events: none;
}

.postdetails {
 flex-basis: 3;
 margin-right: 2rem;
}

.imgdiv {
 display: flex;
 justify-content: center;
 align-items: center;
}



/* Responsive Design */
@media  (max-width: 750px) {

  .card {

  grid-template-columns: 100%;
 
}

.imgdiv {
  grid-row-start: 1;
  margin-bottom: 2rem;
}


}