/* Other rules */
/*  .... COLOR MEMO ....
  background-color: #ec1d24;
  background-color: #9f0013;
  background-color: #100d01;
  background-color: #202020;   lighter* 
 */

:root {
  --color-green1: #1f622f;
  --color-green2: #2e9f46;
  --color-green3: #4fbb4a;
  --color-blue1: #152b5f;
  --color-blue2: #186d9c;
  --color-yellow1: #bdd750;

  /* --color-bg1: #F5F5F5; */
  --color-bg1: #dee2e6;
  --color-bg2: #f4f5f7;
  --color-bg3: #bdd750;
  --color-red: #ec1d24;
  --color-text-1: #222222;
}

/* Hide scrollbars */
.product-desc::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.product-desc {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  /* font-family: "Roboto Condensed", sans-serif; */
  font-family: 'Montserrat', sans-serif;
  background-color: var(--color-bg2);
}

.container {
  width: 100vw;
  min-height: 100vh;
  margin: 20px auto;
}

p {
  margin: 0;
}

.loading {
  min-height: 95vh;
  /* min-width: 100vW; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loading-img {
  max-width: 200px;
}
/* Header and Nav bar */
/* ---------------------------------------------------------------- */
.navbar {
  background-color: var(--color-bg2);
  font-weight: bold;
  margin: 0;
  padding: 0;
  position: sticky;
  z-index: 555;
  top: 0;
}

.banner-container {
  background: linear-gradient(
    90deg,
    rgba(46, 159, 70, 1) 0%,
    rgba(21, 43, 95, 1) 28%,
    rgba(189, 215, 80, 1) 100%
  );
  /* background: linear-gradient(90deg, rgba(46, 159, 70, 1) 0%, rgba(21, 43, 95, 1) 32%, rgba(189, 215, 80, 1) 98%); */
  /* background: radial-gradient(circle, rgba(189, 215, 80, 1) 17%, rgba(46, 159, 70, 1) 94%); */
  /* background-image: url('./../public/img/landingbg.jpg'); */
  width: 100%;
  height: 300px;
  object-fit: scale-down;
  padding: 20px 20vw;
}

.logo {
  padding-top: 5px;
  height: 55px;
}

.nav {
  width: 60vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-items {
  color: var(--color-green1);
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  gap: 15px;
}

.favoris {
  color: #adb5bd;
}

.btn-login {
  margin: 0px 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 36px;
  width: 100%;
  background-color: var(--color-green2);
  color: white;
  border: 1px solid white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}

/* Sign .in ...  -------------------- */

.sign-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 16px;
}

.login-ins {
  font-size: 12px;
  color: var(--color-blue1);
  z-index: 800 !important;
}

.logout-icon {
  margin-left: 10px;
  font-size: 12px;
}

.user {
  color: var(--color-red);
  font-size: 12px;
  outline: var(--color-red);
}

.username {
  color: var(--color-blue1);
}

.signin-icon {
  color: var(--color-green2);
}

.nav-items a,
.sign-in a {
  color: var(--color-blue1);
  text-decoration: none;
}

a {
  color: #6c757d;
}

.nav-items a:hover,
.sign-in a:hover,
.filter-icon:hover,
.page-links:hover {
  color: var(--color-green2);
  cursor: pointer;
}

/* Filter .. Search bar--------------------- */
.filter {
  font-size: 12px;
  height: 42px;
  padding: 1px 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--color-bg1);
  border-top: 1px solid white;
  position: sticky;
  z-index: 100;
  top: 55px;
}

#search-field {
  margin-right: 5px;
  border: none;
}

.search-pan {
  flex-grow: 2;
}
.search-pan select {
  font-size: 14px;
  height: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-sizing: border-box;
  padding-left: 5px;
  max-width: 150px;
}

.filter select:nth-child(n + 2) {
  font-size: 14px;
  /* font-weight: bold; */
}

.filter select option {
  font-size: 14px;
  padding-left: 10px;
  /* font-weight: bold; */
}

.search-bar {
  font-size: 16px;
  height: 30px;
  width: 70%;
  padding-left: 20px;
  margin-left: 0;
  /* background-color: #f5f6f7; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  box-sizing: border-box;
}

.search-bar:focus {
  outline: none;
}

.filter-icon {
  font-size: 16px;
  color: var(--color-blue1);
}

.pages > * {
  margin-left: 10px;
}

.pages select {
  font-size: 16px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Navigation Links ................ */
.page-links {
  padding: 0;
  margin: 20px 0px;
  text-decoration: none;
}

.link-span {
  margin-right: 5px;
}

/* Product Card ................ */
.main-pan {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.card-pan {
  margin: 20px 0px;
}

.product-card {
  position: relative;
  width: 340px;
  height: 510px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: transform 250ms;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.product-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.product-detailspan,
.product-descspan {
  padding: 15px;
}

.product-subheads {
  color: var(--color-text-1);
}

.product-card:hover {
  transform: translateY(-5px);
  color: var(--color-green2);
}

.char-card {
  width: 224px;
  height: 470px;
  background-color: #dee2e6;
  border-bottom-right-radius: 20px;
  transition: transform 250ms;
}
.char-card:hover {
  transform: translateY(-12px);
  background-color: #5390d9;
  color: white;
}

.product-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.product-title {
  color: #4a5568;
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: bold;
}

.product-price {
  color: black;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}

.product-locspan {
  display: flex;
  gap: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.product-desc {
  padding: 0 5px;
  overflow-x: hidden;
}

.fav-pan {
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fav-pan:last-child {
  color: #9f0013;
}

.favicon {
  border-radius: 4px;
  border: none;
  position: absolute;
  background: #ffffff;
  color: #ff8000;
  top: 20px;
  right: 20px;
  height: 30px;
  width: 30px;
}

/* Footer.......................... */

.footer {
  padding: 5px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ebedee;
}

.foot-notes {
  font-size: 12px;
  line-height: 40px;
  color: #0a1128;
  text-decoration: none;
}

.foot-notes a {
  text-decoration: none;
}

.form-pan {
  min-height: 100vh;
}

#terms-conditions,
#privacy-policy,
#contact-us {
  color: #222222;
}

.fav-mobile {
  font-size: 20px;
  padding: 8px 3px;
  color: #ff8000;
}

#fav-mobile {
  color: #ff8000;
}
/* ___________________________________________________________________________________________________________________________________

/* General Typesetting .. Fonts ----------------------------------------------- */

/* Buttons ... misc --------------------------------------------- */

.burger:hover,
.product-card:hover {
  cursor: pointer;
}

.btn-login:hover {
  cursor: pointer;
  background-color: #ffffff;
  color: var(--color-green2);
  border: var(--color-green2) 1px solid;
}

.offers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
}

.card {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 500px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
}

.card > :nth-child(n + 4) {
  margin-left: 8px;
  color: #212529;
}

.card > :nth-child(3) {
  margin-left: 8px;
  color: black;
}

.card > :first-child {
  margin: 8px;
  color: black;
}

.card > p {
  margin-bottom: 5px;
}

.card-image {
  margin-bottom: 10px;
  width: 240px;
  height: 380px;
  object-fit: cover;
}

.product-info {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  gap: 20px;
}

.product-info > button {
  justify-content: center;
}

.product-info > ul {
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
}

.product-info > :first-child {
  font-size: 16px;
  font-weight: bold;
}

.product-keys {
  display: flex;
  font-size: 13px;
  line-height: 19px;
}

.product-keys :first-child {
  color: #6c757d;
  min-width: 60%;
}

.product-keys span {
  flex: 1;
}

.product-keys-checkout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cart-total {
  font-weight: bold;
}

.product-image {
  height: 500px;
  width: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.product,
.login-pan {
  padding-top: 50px;
  margin: 0px;
  min-width: 100vw;
  min-height: 90vh;
  background-color: #ebedee;
}

.login-pan {
  background-color: white;
}

a {
  text-decoration: none;
}

.login-form {
  max-width: 340px;
  margin: 20px auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
}

#offer-form > div > :first-child {
  width: 35%;
  color: #6c757d;
}

.input-txt {
  font-size: 12px;
  margin: 15px auto;
  min-height: 30px;
  width: 100%;
  padding-left: 20px;
  background-color: #f5f6f7;
  border-radius: 5px;
  border: none;
}

.input-txt:focus {
  outline: none;
}

.fav-mobile,
#signin-hide,
.burger {
  display: none;
}

.burger {
  font-size: 30px;
  color: var(--color-green2);
  border: 1px solid var(--color-green2);
  padding: 5px;
  border-radius: 4px;
}

.valuesimg {
  width: 60%;
}

#btnsell {
  color: white;
  border: 1px solid var(--color-green2);
  background-color: var(--color-green2);
  padding: 0.2rem 1rem;
  border-radius: 0.2rem;
}

#btnsell:hover {
  color: var(--color-green2);
  background-color: white;
  padding: 0.2rem 1rem;
  border-radius: 0.2rem;
}

.favicontext{
  display:inline-block;
}

/* RESPONSIVE ... media queries........................................................................................ */

/* First breack point.... */
@media (max-width: 460px) {
  #login-hide,
  #filter-hide,
  .hide {
    display: none;
  }

  .fav-mobile,
  .burger {
    display: flex;
    align-self: center;
  }

  .logo {
    height: 40px;
  }

  .pan-image {
    height: 120px;
    object-fit: cover;
  }

  .logo {
    height: 35px;
  }

  #filter {
    padding: 2px 5%;
    display: flex;
    height: 50px;
    flex-direction: column;
    justify-content: space-between;
    position: static;
  }

  .search-pan {
    margin-top: 3px;
    min-width: 300px;
    height: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
  }

  .search-pan select {
    font-size: 10px;
    height: 20px;
  }

  .filter select:nth-child(n + 2) {
    font-size: 12px;
    font-weight: bold;
    margin: 3px;
  }

  .filter select option {
    font-size: 10px;
  }

  .search-bar {
    font-size: 12px;
    height: 19px;
    flex-grow: 3;
    width: 80% im;
    padding-left: 10px;
  }

  .search-bar:focus {
    outline: none;
  }

  .filter-icon {
    font-size: 16px;
  }

  .pages > * {
    margin-left: 5px;
  }

  .pages select {
    font-size: 16px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  #privacy-policy {
    display: none;
  }

  .nav {
    width: 96vw;
  }

  .nav > div:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .nav > div:last-child {
    width: 100%;
    display: flex;
  }

  .banner-container {
    height: 140px;
    padding: 10px 5%;
  }

  .valuesimg {
    width: 100%;
  }
  .favicontext {
    display: none;
  }
}

@media (min-width: 461px) and (max-width: 560px) {
  #login-hide,
  #filter-hide,
  .hide {
    display: none;
  }

  .fav-mobile,
  .burger {
    display: flex;
    align-self: center;
  }

  .logo {
    height: 40px;
  }

  .pan-image {
    height: 120px;
    object-fit: cover;
  }

  .logo {
    height: 35px;
  }

  #filter {
    padding: 2px 5%;
    display: flex;
    height: 50px;
    flex-direction: column;
    justify-content: space-between;
    position: static;
  }

  .search-pan {
    margin-top: 3px;
    min-width: 300px;
    height: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
  }

  .search-pan select {
    font-size: 10px;
    height: 20px;
  }

  .filter select:nth-child(n + 2) {
    font-size: 12px;
    font-weight: bold;
    margin: 3px;
  }

  .filter select option {
    font-size: 10px;
  }

  .search-bar {
    font-size: 12px;
    height: 19px;
    flex-grow: 3;
    width: 80% im;
    padding-left: 10px;
  }

  .search-bar:focus {
    outline: none;
  }

  .filter-icon {
    font-size: 16px;
  }

  .pages > * {
    margin-left: 5px;
  }

  .pages select {
    font-size: 16px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  #privacy-policy {
    display: none;
  }

  .nav {
    width: 90vw;
  }

  .nav > div:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .nav > div:last-child {
    width: 100%;
    display: flex;
  }

  .banner-container {
    height: 200px;
    padding: 10px 5%;
  }

  .valuesimg {
    width: 100%;
  }
  .favicontext {
    display: none;
  }
}
/* Break Point ----------------------------------- */
@media (min-width: 561px) and (max-width: 750px) {
  #login-hide,
  #filter-hide,
  .hide {
    display: none;
  }

  .logo {
    height: 40px;
  }

  .pan-image {
    height: 200px;
  }

  .fav-mobile,
  .burger {
    display: flex;
    align-self: center;
  }

  .nav > div:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .nav > div:last-child {
    width: 100%;
    display: flex;
  }

  .search-pan select {
    max-width: 90px;
  }

  /* ----------------------------------------------------- */
  #filter {
    padding: 0px 10%;
    justify-content: space-between;
    position: static;
  }

  .search-pan {
    flex-grow: 2;
  }

  .search-pan select {
    font-size: 12px;
  }
  .filter select:nth-child(n + 2) {
    font-size: 12px;
  }

  .filter select option {
    font-size: 12px;
  }

  .search-bar {
    padding-left: 10px;
    font-size: 12px;
    width: 50%;
  }

  /* ----------------------------------------------------- */

  .nav {
    width: 90vw;
  }

  .container {
    width: 90vw;
    gap: 10px;
  }

  .banner-container {
    height: 260px;
    padding: 10px 5vw;
  }

  .valuesimg {
    width: 100%;
  }
}

.favicontext {
  display: none;
}

/* Break Point --------------------------------- */
@media (min-width: 751px) and (max-width: 900px) {
  .pan-image {
    height: 300px;
  }

  .filter {
    padding: 0 10%;
    top: 48px !important;
  }

  .logo {
    height: 45px;
  }

  .container,
  .nav {
    width: 80vw;
  }

  .search-pan select {
    max-width: 100px;
  }

  .fav-mobile {
    display: flex;
  }

  .burger {
    display: none;
  }
  .search-bar {
    font-size: 12px;
    width: 55%;
  }

  .filter select option {
    font-size: 12px;
  }

  .filter select option {
    font-size: 12px;
  }

  .container,
  .main-pan {
    width: 100vw;
    gap: 15px;
  }

  .banner-container {
    height: 260px;
    padding: 10px 5vw;
  }

  .valuesimg {
    width: 80%;
  }
}

.favicontext {
  display: none;
}

@media (min-width: 901px) and (max-width: 1050px) {
  .container,
  .main-pan {
    width: 95vw;
    gap: 15px;
  }

  .search-bar {
    font-size: 12px;
    max-width: 65%;
  }
  .logo {
    height: 50px;
  }
  .search-pan select {
    max-width: 100px;
  }

  .filter {
    padding: 0 10%;
    top: 50px;
  }

  .banner-container {
    height: 300px;
    padding: 20px 10vw;
  }
  .valuesimg {
    width: 90%;
  }
  .favicontext {
  display:inline-block;
}
}

/* Break Point --------------------------------- */
@media (min-width: 1051px) {
  .search-bar {
    font-size: 12px;
    width: 60%;
  }

  .filter {
    padding: 0 20%;
    top: 55px;
  }
    .favicontext {
  display:inline-block;
}
}
