:root {
    --color-green1: #1F622F;
    --color-green2: #2E9F46;
    --color-green3: #4FBB4A;
    --color-blue1: #152B5F;
    --color-blue2: #186D9C;
    --color-yellow1: #BDD750;

    /* --color-bg1: #F5F5F5; */
    --color-bg1: #dee2e6;
    --color-bg2: #F4F5F7;
    --color-bg3: #BDD750;
    --color-bg4: #f8f9fa;
    --color-red: #EC1D24;
}

.pan {

    margin: 0px auto;
    padding: 20px 20%;
    background-color: var(--color-bg4);
    min-height: 75vh;

}

.bullets {
    list-style-type: square;
    margin-left: 20px;
}

.bullets strong {
    font-weight: 600;
}

strong{
    font-weight: 500;
}


.servicecard {
    background-color: white;
    width: 100%;
    /* max-width: 400px;;
    height: 400px; */
    padding: 20px;
    margin: 20px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.servicecard p {
    color: #222222;
    font-size: 22px;
    font-weight: 300;
    margin-top: 1rem;
}

.servicecard h5 {
    color: var(--color-green2);
}

 .btn {
        display: inline-block;
        margin: 12px;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
        color: white !important;
        border: 1px solid white;
 }

 .btn:hover {
    cursor: pointer;
 }

 .quote {
     background-color: var(--color-blue1);
 }

  .quote:hover {
     background-color: #ffffff;
     color: var(--color-blue1) !important;
     border: var(--color-blue1) 1px solid;
 }

 .desc{
    line-height: 1.8rem;
 }


 .content a {
  pointer-events: none;
}