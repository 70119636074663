/* Other rules */
/*  .... COLOR MEMO ....
  background-color: #ec1d24;
  background-color: #9f0013;
  background-color: #100d01;
  background-color: #202020;   lighter* 
 */

:root {
  --color-green1: #1f622f;
  --color-green2: #2e9f46;
  --color-green3: #4fbb4a;
  --color-blue1: #152b5f;
  --color-blue2: #186d9c;
  --color-yellow1: #bdd750;

  /* --color-bg1: #F5F5F5; */
  --color-bg1: #dee2e6;
  --color-bg2: #f4f5f7;
  --color-bg3: #bdd750;
  --color-red: #ec1d24;
  --color-text-1: #222222;
}

.filtericon:hover,
.page-links:hover {
  color: var(--color-green2);
  cursor: pointer;
}

/* Filter .. Search bar--------------------- */
.filter {
  background-color: #ec1d24;
  font-size: 12px;
  height: 42px;
  padding: 1px 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-bg1);
  border-top: 1px solid white;
  position: sticky;
  z-index: 100;
  top: 55px;
}

.searchfield {
  margin-right: 5px;
  border: none;
}

.searchpan {
  display: flex;
  flex-grow: 1;
}

.searchpan select {
  font-size: 14px;
  height: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-sizing: border-box;
  padding-left: 5px;
}

.filter select:nth-child(n + 2) {
  font-size: 14px;
}

.filter select option {
  font-size: 14px;
  padding-left: 10px;
}

.searchbar {
  width: 100%;
  font-size: 16px;
  height: 30px;
  padding-left: 20px;
  margin-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  box-sizing: border-box;
}

.searchbar:focus {
  outline: none;
}

.filtericon {
  font-size: 16px;
  color: var(--color-blue1);
}

.pages {
  display: flex;
  align-items: center;
}

.pages > * {
  margin-left: 10px;
}

.pages select {
  font-size: 16px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Navigation Links ................ */
.page-links {
  padding: 0;
  margin: 20px 0px;
  text-decoration: none;
}

.link-span {
  margin-right: 5px;
}

/* RESPONSIVE ... media queries........................................................................................ */

/* First breack point.... */
@media (max-width: 460px) {
  .filter {
    font-size: 12px;
    height: 92px;
    padding: 2px 2%;
    flex-direction: column;
    justify-content: space-between;
    position: static;
  }

  .searchpan select {
    min-width: 100px;
    margin: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  .searchpan {
    gap: 2px;
    flex-direction: column;
    width: 100%;
  }

  .searchpan select {
    font-size: 0.8rem;
  }

  .filter select:nth-child(n + 2) {
    font-size: 0.8rem;
  }

  .filter select option {
    font-size: 0.8rem;
  }

  .searchbar {
    font-size: 0.8rem;
    padding-left: 10px;
      border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
}

@media (min-width: 461px) and (max-width: 560px) {
  .filter {
    font-size: 12px;
    height: 70px;
    padding: 5px 5%;
    flex-direction: column;
    justify-content: space-between;
    position: static;
  }

  .searchpan select {
    min-width: 180px;
  }

  .searchpan {
    width: 100%;
  }

  .searchpan select {
    font-size: 0.8rem;
  }

  .filter select:nth-child(n + 2) {
    font-size: 0.8rem;
  }

  .filter select option {
    font-size: 0.8rem;
  }

  .searchbar {
    font-size: 0.8rem;
  }
}
/* Break Point ----------------------------------- */
@media (min-width: 561px) and (max-width: 750px) {
  .searchpan select {
    min-width: 120px;
  }

  .filter {
    padding: 0px 5%;
    justify-content: space-between;
    position: static;
  }

  .searchpan select {
    font-size: 12px;
  }

  .filter select:nth-child(n + 2) {
    font-size: 12px;
  }

  .filter select option {
    font-size: 2px;
  }

  .searchbar {
    font-size: 12px;
    padding-left: 5px;
  }
}

/* Break Point --------------------------------- */
@media (min-width: 751px) and (max-width: 900px) {
  .filter {
    padding: 0 10%;
    top: 48px !important;
  }

  .searchpan select {
    min-width: 160px;
  }

  .searchbar {
    font-size: 12px;
  }

  .filter select option {
    font-size: 12px;
  }


}

@media (min-width: 901px) and (max-width: 1050px) {
  .searchbar {
    font-size: 12px;
  }

  .searchpan select {
    max-width: 160px;
  }

  .filter {
    padding: 0 10%;
    top: 50px;
  }
}

/* Break Point --------------------------------- */
@media (min-width: 1051px) and (max-width: 1250px) {
  .searchbar {
    font-size: 12px;
  }

  .filter {
    padding: 0 20%;
    top: 55px;
  }

  .searchpan select {
    max-width: 180px;
  }
}

@media (min-width: 1251px) {
  .searchpan select {
    min-width: 250px;
  }
}
