:root {
  --color-green1: #1f622f;
  --color-green2: #2e9f46;
  --color-green3: #4fbb4a;
  --color-blue1: #152b5f;
  --color-blue2: #186d9c;
  --color-yellow1: #bdd750;

  /* --color-bg1: #F5F5F5; */
  --color-bg1: #dee2e6;
  --color-bg2: #f4f5f7;
  --color-bg3: #bdd750;
  --color-bg4: #f8f9fa;
  --color-red: #ec1d24;
}

.btn {
  align-self: center;
  width: 180px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  border: 1px solid white;
}

.btn:hover {
  cursor: pointer;
}

.btnsubmit {
  background-color: var(--color-green3);
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.btnsubmit:hover {
  background-color: var(--color-green2);
  color: white;
}

.btncancel {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.carthead {
  display: flex;
  margin-bottom: 1px;
  padding: 1rem;
  background-color: #ffffff;
  color: var(--color-blue1);
  font-size: 1.2rem;
  justify-content: space-between;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.cartvalue {
  display: flex;
  padding: 1rem;
  background-color: #ffffff;
  color: var(--color-blue1);
  font-size: 1.2rem;
  justify-content: space-between;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.cart {
  max-width: 100vw;
}
