.signupcandy {
  max-width: 340px;
  margin: 20px auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
}

.btnsignup {
  margin: 0px 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 36px;
  width: 100%;
  background-color: #a61c40;
  color: white;
  border: 1px solid white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}

.tick {
    color: #a61c40;
    margin-right: 1rem;
}

.litem {
  display: flex;
  margin-bottom: .5rem;
}