:root {
    --color-green1: #1F622F;
    --color-green2: #2E9F46;
    --color-green3: #4FBB4A;
    --color-blue1: #152B5F;
    --color-blue2: #186D9C;
    --color-yellow1: #BDD750;

    /* --color-bg1: #F5F5F5; */
    --color-bg1: #dee2e6;
    --color-bg2: #F4F5F7;
    --color-bg3: #BDD750;
    --color-bg4: #f8f9fa;
    --color-red: #EC1D24;
}

.pan {

    margin: 20px auto;
    padding: 0 20%;
    background-color: var(--color-bg4);
    min-height: 92vh;

}

.btnsignup {
    background-color: var(--color-green3);
    font-size: 16px;
    font-weight: 600;
    color: white;
    width: 90%;
    max-width: 400px;

}

.btnsignup:hover {
    background-color: var(--color-green2);
    color: white;
    margin: 10px auto;
}


.card {
    font-size: 1, 5rem;
    padding: 3rem;
    border-radius: 5px;
    margin: 0px auto;
    background-color: #ffffff;
}

.feedback {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
}

/* RESPONSIVE ... media queries........................................................................................ */

@media (max-width: 600px) {

    .card {
        font-size: 0, 8rem;
        padding: 10px 5px;
    }

    .pan {
        padding: 0 10%;
    }

    h4 {
        font-size: 1rem !important;
    }

    span,
    a {
        font-size: 12px !important;
    }

}