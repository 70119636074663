:root {
    --color-green1: #1F622F;
    --color-green2: #2E9F46;
    --color-green3: #4FBB4A;
    --color-blue1: #152B5F;
    --color-blue2: #186D9C;
    --color-yellow1: #BDD750;

    /* --color-bg1: #F5F5F5; */
    --color-bg1: #dee2e6;
    --color-bg2: #F4F5F7;
    --color-bg3: #BDD750;
    --color-bg4: #f8f9fa;
    --color-red: #EC1D24;
}

.pan {

    margin: 0px auto;
    padding: 20px 20%;
    background-color: var(--color-bg4);
    min-height: 75vh;
}

.desc {
  line-height: 1.6rem;
}


/* Timeline Container */
.timeline {
  margin: 20px auto;
  padding: 20px;
}

/* Card container */
.card {
  position: relative;
  max-width: 800px;
}

/* setting padding based on even or odd */
.card:nth-child(odd) {
  padding: 30px 0 30px 30px;
}
.card:nth-child(even) {
  padding: 30px 30px 30px 0;
}
/* Global ::before */
.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid var(--color-green2);
}

/* Setting the border of top, bottom, left */
.card:nth-child(odd)::before {
  left: 0px;
  top: -4.5px;
  bottom: -4.5px;
  border-width: 5px 0 5px 5px;
  border-radius: 50px 0 0 50px;
}

/* Setting the border of top, bottom, right */
.card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: 0;
  border-width: 5px 5px 5px 0;
  border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  background: white;
  color: gray;
  border-radius: 10px;
  padding: 10px;
}

/* Title of the card */
.title {
  color: var(--color-green2);
  position: relative;
  margin: 20px;
}

/* Timeline dot  */
.title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  border: 3px solid orangered;
}

/* text right if the card is even  */
.card:nth-child(even) > .info > .title {
  text-align: right;
}

/* setting dot to the left if the card is odd */
.card:nth-child(odd) > .info > .title::before {
  left: -45px;
}

/* setting dot to the right if the card is odd */
.card:nth-child(even) > .info > .title::before {
  right: -45px;
}

.img {
  width: 100%;
  /* aspect-ratio: 2/1; */
}

.bullet::before { /* add the new bullet point */
  display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.5rem;
    background-color: var(--color-green2);
}