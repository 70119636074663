:root {
  --color-green1: #1f622f;
  --color-green2: #2e9f46;
  --color-green3: #4fbb4a;
  --color-blue1: #152b5f;
  --color-blue2: #186d9c;
  --color-yellow1: #bdd750;

  /* --color-bg1: #F5F5F5; */
  --color-bg1: #dee2e6;
  --color-bg2: #f4f5f7;
  --color-bg3: #bdd750;
  --color-bg4: #f8f9fa;
  --color-red: #ec1d24;
}

.pan {
  margin: 20px auto;
  padding: 0 20%;
  background-color: var(--color-bg4);
}

.btnsubmit {
  background-color: var(--color-green3);
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.btncancel {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.btnsubmit:hover {
  background-color: var(--color-green2);
  color: white;
}

.card {
  font-size: 1, 5rem;
  padding: 2rem;
  border-radius: 5px;
  margin: 0px auto;
  background-color: #ffffff;
}

.thumbnail {
  max-width: 150px;
  position: relative;
}

.thumb {
  position: relative;
}

.delimg {
  border-radius: 4px;
  border: none;
  position: absolute;
  background: #ffffff;
  color: #ec1d24;
  top: 10px;
  right: 20px;
  height: 30px;
  width: 30px;
}

.zone {
    display: flex;
}

.filezone {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.filezone:hover {
    cursor: pointer;
    border-color: #2196f3;
}

.hasfiles {
   border-color: #00e676;
}

/* RESPONSIVE ... media queries........................................................................................ */

@media (max-width: 600px) {
  .card {
    font-size: 0, 8rem;
    padding: 10px 5px;
  }

  .thumbnail {
    max-width: 115px;
    position: relative;
  }

  .delimg {
    top: 9px;
    right: 20px;
    height: 20px;
    width: 20px;
    font-size: 12px;
  }

  .pan {
    padding: 0 10%;
  }

  h4 {
    font-size: 1rem !important;
  }

  span,
  a {
    font-size: 12px !important;
  }
}
