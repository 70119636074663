:root {
  --primary-text: #5e5e5e;
    --color-green2: #2e9f46;
  --secondary-text: #444444;
  --text-light: #495057;
  --primary-color: #ed6ba7;
  --prodcard-color: #f8f8f8;
  --border-light: #ced4da;
}

.counter {
  display: inline-flex;
  align-items: center;
  padding: .1rem .5rem;
  color: var(--color-green2);
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--border-light);
  max-height: 2.5rem;
}

.counter > btn {
  font-size: 2rem;
  font-weight: 400;
}

.carticon {
  font-size: 1.5rem;
}


.cartqty {
  font-size: 1.2rem;
  max-width: 5rem;
  border: none;
  text-align: center;
}

.counter:hover,
.smcounter:hover {
  cursor: pointer;
}
