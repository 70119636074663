:root {
  --color-green1: #1f622f;
  --color-green2: #2e9f46;
  --color-green3: #4fbb4a;
  --color-blue1: #152b5f;
  --color-blue2: #186d9c;
  --color-yellow1: #bdd750;

  /* --color-bg1: #F5F5F5; */
  --color-bg1: #dee2e6;
  --color-bg2: #f4f5f7;
  --color-bg3: #bdd750;
  --color-red: #ec1d24;
  --color-text-1: #222222;
}

.productcard {
  height: 260px;
  background-color: var(--color-green2);
  color: white;
  display: flex;
  border-radius: 10px;
  transition: transform 250ms;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.productimg {
  width: 390px;
  height: 260px;
  object-fit: cover;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.productdetails {
  display: flex;
  flex-direction: column;
}

.productdetailspan {
  padding: 10px;
}

.productcard:hover {
  cursor: pointer;
  transform: translateY(-2px);
}

.producttitle {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.price {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
}

.productlocspan {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.desc {
  flex-shrink: 2;
  padding: 10px;
  overflow: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.paragraph {
  white-space: pre-wrap;
}

/* RESPONSIVE ... media queries........................................................................................ */

/* First breack point.... */
@media (max-width: 460px) {
  .productcard {
    height: 120px;
  }

  .productimg {
    width: 180px;
    height: 120px;
  }

  .productdetailspan {
    padding: 5px;
  }

  .producttitle {
    margin-bottom: 5px;
    font-size: 10px;
    font-weight: bold;
  }

  .price {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 300;
  }

  .subheads {
    font-size: 10px;
  }

  .productlocspan {
    gap: 5px;
    padding: 5px;
  }

  .desc {
    display: none;
  }
}

/* 2nd  breack point.... */

@media (min-width: 461px) and (max-width: 560px) {
  .productcard {
    height: 180px;
  }

  .productimg {
    width: 270px;
    height: 180px;
  }

  .producttitle {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
  }

  .price {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 300;
  }

  .subheads {
    font-size: 12px;
  }

  .productlocspan {
    gap: 10px;
    padding: 10px;
  }

  .desc {
    display: none;
  }
}

/* 3rd Break Point ----------------------------------- */
@media (min-width: 561px) and (max-width: 750px) {
  .productcard {
    height: 240px;
  }

  .productimg {
    width: 360px;
    height: 240px;
  }

  .desc {
    display: none;
  }
}

/* 4th Break Point --------------------------------- */
@media (min-width: 751px) and (max-width: 900px) {
  .productcard {
    height: 240px;
  }

  .productimg {
    width: 360px;
    height: 240px;
  }

  .desc {
    display: none;
  }
}

/* 5th Break Point --------------------------------- */
@media (min-width: 901px) and (max-width: 1050px) {
  .productdetailspan {
    padding-right: 30px;
  }
}

/* 6th Break Point --------------------------------- */
@media (min-width: 1051px) {
  .productdetailspan {
    padding-right: 50px;
  }
}
