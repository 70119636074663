 :root {
     --color-green1: #1F622F;
     --color-green2: #2E9F46;
     --color-edit: #fca311;
     --color-bg-white: #F8F9FA;
     --color-red: #d90429;
 }

 .card {
     background-color: var(--color-bg-white);
     border-radius: 5px;

 }

 .img {
     max-width: 100%;
     object-fit: contain;
 }

 .label {
     font-weight: 500;
     color: #000000;
     margin-right: 10px;
 }

 .btn {
        align-self: center;
        width: 180px;
        height: 30px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
        color: white;
        border: 1px solid white;
 }

 .btn:hover {
    cursor: pointer;
 }

 .quote {
     background-color: var(--color-green2);
 }

  .edit {
      background-color: var(--color-edit);
  }

 .del {
     background-color: var(--color-red);
 }


 .quote:hover {
     background-color: #ffffff;
     color: var(--color-green2);
     border: var(--color-green2) 1px solid;
 }

  .edit:hover {
      background-color: #ffffff;
      color: var(--color-edit);
      border: var(--color-edit) 1px solid;
  }

 .del:hover {
     background-color: #ffffff;
     color: var(--color-red);
     border: var(--color-red) 1px solid;
 }

 .paragraph {
     white-space: pre-wrap;
 }


 /* RESPONSIVE ... media queries........................................................................................ */
 @media (max-width: 600px) {

     .label, .value {
         font-size: 1rem !important;
     }

 }