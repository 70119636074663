:root {
  --primary-text: #5e5e5e;
    --color-green2: #2e9f46;
  --secondary-text: #444444;
  --text-light: #495057;
  --primary-color: #ed6ba7;
  --prodcard-color: #f8f8f8;
  --border-light: #ced4da;
}

:root {
    --color-green1: #1F622F;
    --color-green2: #2E9F46;
    --color-green3: #4FBB4A;
    --color-blue1: #152B5F;
    --color-blue2: #186D9C;
    --color-yellow1: #BDD750;

    /* --color-bg1: #F5F5F5; */
    --color-bg1: #dee2e6;
    --color-bg2: #F4F5F7;
    --color-bg3: #BDD750;
    --color-bg4: #f8f9fa;
    --color-red: #EC1D24;
}

.btnsubmit {
   
    background-color: var(--color-green3);
    font-size: 16px;
    font-weight: 600;
    color: white;

}

.btnsubmit:hover {
    background-color: var(--color-green2);
    color: white;
}

.btncancel{

    font-size: 16px;
    font-weight: 600;
    color: white;

}


.cartvalue {
    display: flex;
    padding: 2rem;
    background-color: var(--color-bg1);
    color: var(--color-blue1);
    font-size: 1.5rem;
    justify-content: space-between;

}

.card {
  font-size: 1,5rem;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 0,5rem;
  background-color: #ffffff;
} 


@media (max-width: 460px) {

.card {
  font-size: 1rem;
  padding: .5rem;
  border-radius: 3px;
} 

}