 :root {
     --color-green1: #1F622F;
     --color-green2: #2E9F46;
     --color-green3: #4FBB4A;
     --color-blue1: #152B5F;
     --color-blue2: #186D9C;
     --color-yellow1: #BDD750;

     /* --color-bg1: #F5F5F5; */
     --color-bg1: #dee2e6;
     --color-bg2: #F4F5F7;
     --color-bg3: #BDD750;
     --color-red: #EC1D24;
 }


.footerbody {
    
    font-size: 14px;
    background-color: #ffffff;
    padding: 50px 20vW;
    margin: auto;
    display: flex;
    justify-content: space-between;
    color: #0a1128;
}


.logopan {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;
    align-items: flex-start;
    color: #0a1128;
}

.contactpan, .aboutpan{

    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}


.mediaicons {
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.contactpan p:first-of-type, .aboutpan p:first-of-type {
    font-weight: 500;
}



/* Break Point --------------------------------- */
@media  (max-width: 450px) {

    .footerbody {
        padding: 20px 10vW;
        font-size: 12px;
        flex-direction: column;
        gap: 20px;

    }

}

/* Break Point --------------------------------- */
@media (min-width: 451px) and (max-width: 750px) {

    .footerbody {
        padding: 20px 5vW;
        font-size: 12px;
        justify-content: space-between;
    }

}

/* Break Point --------------------------------- */
@media (min-width: 751px) and (max-width: 900px) {
.footerbody {
        padding: 30px 15vW;
     
    }

}