:root {
  --color-green1: #1f622f;
  --color-green2: #2e9f46;
  --color-green3: #4fbb4a;
  --color-blue1: #152b5f;
  --color-blue2: #186d9c;
  --color-yellow1: #bdd750;

  /* --color-bg1: #F5F5F5; */
  --color-bg1: #dee2e6;
  --color-bg2: #f4f5f7;
  --color-bg3: #bdd750;
  --color-bg4: #f8f9fa;
  --color-red: #ec1d24;
}

.pan {
  padding: 1rem;
  background-color: #fff;
  min-height: 75vh;
}

.bullets {
  list-style-type: square;
  margin-left: 20px;
}

.bullets strong {
  font-weight: 600;
}

strong {
  font-weight: 500;
}

.btn:hover {
  cursor: pointer;
}

.quote {
  background-color: var(--color-blue1);
}

.quote:hover {
  background-color: #ffffff;
  color: var(--color-blue1) !important;
  border: var(--color-blue1) 1px solid;
}

.desc {
  line-height: 1.8rem;
}

.content a {
  pointer-events: none;
}

.content img {
    max-width: 80%;
}

.post{
  html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: revert;
  padding: revert;
  border: revert;
  font-size: revert;
  font: revert;
  vertical-align: revert;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: revert;
}
body {
  line-height: revert;
}
ol,
ul {
  list-style: revert;
}
blockquote,
q {
  quotes: revert;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: revert;
}
table {
  border-collapse: revert;
  border-spacing: revert;
}
img {
  max-width: 100%;
}

}

.keyword {
    text-decoration: none;
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 15px;
    display: inline-block;
    margin: 4px 4px 4px 0;
    padding: 3px 8px;
}