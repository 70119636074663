:root {
  --color-green1: #1f622f;
  --color-green2: #2e9f46;
  --color-green3: #4fbb4a;
  --color-blue1: #152b5f;
  --color-blue2: #186d9c;
  --color-yellow1: #bdd750;

  /* --color-bg1: #F5F5F5; */
  --color-bg1: #dee2e6;
  --color-bg2: #f4f5f7;
  --color-bg3: #bdd750;
  --color-bg4: #f8f9fa;
  --color-red: #ec1d24;
}


.card {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.pricepan {
     width: 30vw;
  min-width: 400px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  min-height: 340px;
}

.cartvalue {
  display: flex;
  padding: 1rem;
  background-color: #ffffff;
  color: var(--color-blue1);
  font-size: 1.2rem;
  justify-content: space-between;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.cart {
  max-width: 100vw;
}
